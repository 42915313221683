<template>
    <div>
        <v-row no-gutters dense class="my-card d-flex flex-column">
            <div class="pa-0">
                <v-row no-gutters dense class="d-flex flex-grow-0">
                    <v-col cols="5" class="pa-0">
                        <p class="my-heading">Contractors</p>
                    </v-col>
                    <v-col cols="6" class="pa-0 filter-search">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            color="#CACEDA"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="export-icon">
                        <div
                            v-on:click="getContractorList()"
                            style="cursor: pointer"
                        >
                            <v-tooltip left color="#757575">
                                <template v-slot:activator="{ on }">
                                    <img
                                        v-on="on"
                                        :src="
                                            $store.state.icons.icons[
                                                'file-export'
                                            ]
                                        "
                                        style="
                                            height: 24px;
                                            filter: invert(61%) sepia(10%)
                                                saturate(1008%)
                                                hue-rotate(178deg)
                                                brightness(84%) contrast(84%);
                                        "
                                        alt
                                    />
                                </template>
                                <span>Export contractor detail</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-data-table
                :items="contractorList"
                :page.sync="page"
                :headers="header"
                :items-per-page="itemsPerPage"
                :search="search"
                hide-default-footer
                mobile-breakpoint="1080"
                no-data-text="No record found"
            >
                <template v-slot:item.status="{ item }">
                    <div
                        v-if="item.status === 'A'"
                        class="
                            text-tag-green text-tag-btn
                            d-flex
                            align-center
                            justify-center
                            my-2
                        "
                    >
                        Active
                    </div>
                    <div
                        v-else-if="item.status === 'I'"
                        class="
                            text-tag-blue text-tag-btn
                            d-flex
                            align-center
                            justify-center
                            my-2
                        "
                    >
                        Inactive
                    </div>
                    <div
                        v-else-if="item.status === 'D'"
                        class="
                            text-tag-red text-tag-btn
                            d-flex
                            align-center
                            justify-center
                            my-2
                        "
                    >
                        Deleted
                    </div>
                    <div
                        v-else-if="item.status === 'S'"
                        class="
                            text-tag-blue text-tag-btn
                            d-flex
                            align-center
                            justify-center
                            my-2
                        "
                    >
                        Suspend
                    </div>
                </template>
                <template v-slot:item.list_count="{ item }">
                    <div v-if="item.list_count != '0'">
                        <v-tooltip left color="#757575">
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="my-pointer"
                                    v-on:click="showLists(item.id)"
                                >
                                    {{ item.list_count }}
                                </div>
                            </template>
                            <span>Get all list of contractor</span>
                        </v-tooltip>
                    </div>
                    <div v-else>{{ item.list_count }}</div>
                </template>
                <template v-slot:item.delivery_count="{ item }">
                    <div v-if="item.delivery_count != '0'">
                        <v-tooltip left color="#757575">
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="my-pointer"
                                    v-on:click="showDeliveries(item.id)"
                                >
                                    {{ item.delivery_count }}
                                </div>
                            </template>
                            <span>Get all deliveries of contractor</span>
                        </v-tooltip>
                    </div>
                    <div v-else>{{ item.delivery_count }}</div>
                </template>
                <template v-slot:item.total_payment="{ item }">
                    <div>
                        {{ $options.filters.currency(item.total_payment) }}
                    </div>
                </template>
                <template v-slot:item.total_mmb_fee="{ item }">
                    <div>
                        {{ $options.filters.currency(item.total_mmb_fee) }}
                    </div>
                </template>
                <template v-slot:item.last_login="{ item }">
                    <div>
                        {{
                            item.last_login ? formatDate(item.last_login) : "-"
                        }}
                    </div>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip left color="#757575">
                        <template v-slot:activator="{ on }">
                            <div
                                v-on="on"
                                class="my-pointer"
                                v-on:click="userEdit(item)"
                            >
                                <img
                                    :src="$store.state.icons.icons['user-edit']"
                                    class="table-icon"
                                />
                            </div>
                        </template>
                        <span>Contactor profile</span>
                    </v-tooltip>
                </template>
            </v-data-table>

            <div
                class="d-flex align-center justify-center my-tabel-footer py-2"
            >
                <v-pagination
                    v-model="page"
                    color="#2974FF"
                    :total-visible="7"
                    :length="
                        getTotalPage(
                            this.$store.state.contractorlist.contractor_list
                        )
                    "
                ></v-pagination>
            </div>
        </v-row>
        <app-user-edit ref="useredit"></app-user-edit>
    </div>
</template>

<script>
import moment from "moment-timezone";
import { ExportToCsv } from "export-to-csv";
const UserEdit = () => import("../common/UserEdit");
export default {
    components: {
        AppUserEdit: UserEdit,
    },

    data() {
        return {
            page: 1,
            itemsPerPage: 10,
            search: "",
            contractor_data: [],
            header: [
                { text: "Fullname", value: "fullname" },
                { text: "Email", value: "email" },
                { text: "Phone", value: "phone" },
                { text: "Lists", value: "list_count" },
                { text: "Deliveries", value: "delivery_count" },
                { text: "Projects", value: "project_count" },
                { text: "Payment", value: "total_payment" },
                { text: "Total MMB Fee", value: "total_mmb_fee" },
                { text: "Status", value: "status" },
                { text: "Last Login", value: "last_login" },
                {
                    text: "Action",
                    value: "action",
                    sortable: false,
                    align: "center",
                },
            ],
        };
    },

    mounted() {
        let data = this.$store.getters.getContractor;
        if (data.length === 0) {
            this.$store.dispatch("contList");
        }
    },

    computed: {
        contractorList() {
            return this.$store.getters.getContractor;
        },
    },

    methods: {
        formatDate(data) {
            return moment(data).format("M-D-Y hh:mm A");
        },

        getTotalPage(data) {
            return Math.ceil(data.length / this.itemsPerPage);
        },

        userEdit(item) {
            this.$refs.useredit.openUserEdit(item.id);
        },

        userDelete(item) {
            item.status = "D";
            this.$store.dispatch("contractorDelete", item);
            let alert = {
                show: true,
                message: item.fullname + " is deleted",
                type: "success",
            };
            this.$store.commit("showAlert", alert);
        },

        userSuspend(item) {
            item.status = "S";
            this.$store.dispatch("contractorSuspend", item);
            let alert = {
                show: true,
                message: item.fullname + " is suspended",
                type: "success",
            };
            this.$store.commit("showAlert", alert);
        },

        showLists(id) {
            this.$store.dispatch("listOfCont", id);
            this.$router.push({ path: "/contractors/lists/" + id });
        },

        showDeliveries(id) {
            this.$store.dispatch("deliveryOfCont", id);
            this.$router.push({ path: "/contractors/deliveries/" + id });
        },
        // async contractorListExport() {
        // 	let data = await contractorExport();
        // 	let url = data.data;
        // 	window.open(url, "_blank");
        // },
        getContractorList() {
            this.contractor_data = this.$store.getters.getContractor;
            const options = {
                filename: "Contractor List",
                fieldSeparator: ",",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title: "Contractor List",
                useTextFile: false,
                useBom: true,
                // useKeysAsHeaders: true,
                headers: [
                    "Fullname",
                    "Email",
                    "Phone",
                    "Lists",
                    "Deliveries",
                    "Projects",
                    "Payment",
                    "Total_MMB_Fee",
                    "Status",
                    "Last Login",
                ],
            };

            const csvExporter = new ExportToCsv(options);

            let contractor_ob = [];
            this.contractor_data.forEach((data) => {
                let contractor = {};
                contractor.fullname = data.fullname;
                contractor.email = data.email;
                contractor.phone = data.phone;
                contractor.list_count = data.list_count;
                contractor.delivery_count = data.delivery_count;
                contractor.project_count = data.project_count;
                contractor.total_payment = data.total_payment;
                contractor.total_mmb_fee = data.total_mmb_fee;
                contractor.last_login = data.last_login;
                if (data.status === "A") {
                    contractor.status = "Active";
                } else if (data.status === "I") {
                    contractor.status = "Inactive";
                } else if (data.status === "D") {
                    contractor.status = "Deleted";
                } else if (data.status === "S") {
                    contractor.status = "Suspended";
                }
                contractor_ob.push(contractor);
            });
            csvExporter;
            csvExporter.generateCsv(contractor_ob);
        },
    },
};
</script>
